<template>
  <div>
    <h2>Edit User Dictionary</h2>
    <TextInput
      placeHolder="Search..."
      v-model="searchText"
      class="word-search-panel"
      v-if="userWords.length"
    />
    <div v-if="isLoading">Loading user dictionary...</div>
    <div v-else-if="!displayList.length">
      {{ searchText.length ? "No results found." : "User dictionary is empty." }}
    </div>
    <div v-else>
      <div v-for="word of displayList" v-bind:key="word">
        <div class="d-flex justify-content-between m-1 p-1 word-item border">
          <div>{{ word }}</div>
          <IconButton
            class="close-btn btn p-0 text-primary"
            icon="times"
            ref="button"
            v-tooltip="'Click to remove word from dictionary.'"
            @click.prevent="removeWord(word)"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end m-1">
      <button class="btn btn-primary" @click="handleClose">Done</button>
    </div>
  </div>
</template>

<script>
import { handleErrors } from "@/modules/handleErrors";
import { SpellCheckApi } from "@/services";

import IconButton from "./common/IconButton.vue";
import TextInput from "./common/TextInput.vue";
export default {
  props: ["userId"],
  components: { IconButton, TextInput },
  data() {
    return {
      userWords: [],
      isLoading: false,
      searchText: ""
    };
  },
  watch: {
    userId: {
      immediate: true,
      handler(nv) {
        this.getUserDictionaryWords(nv);
      }
    }
  },
  computed: {
    displayList() {
      if (this.searchText) {
        return this.userWords.filter(e => e.startsWith(this.searchText));
      }
      return this.userWords;
    }
  },
  methods: {
    getUserDictionaryWords(userId, hideLoading) {
      if (!hideLoading) {
        this.isLoading = true;
      }
      SpellCheckApi.getUserWordList(userId)
        .then(res => {
          if (res?.words?.length) {
            this.userWords = res.words.sort();
          } else {
            this.userWords = [];
          }
          this.isLoading = false;
        })
        .catch(error => {
          handleErrors(error);
          this.isLoading = false;
        });
    },
    removeWord(word) {
      SpellCheckApi.removeWordsFromDictionary([word]).then(() => {
        window.notify(`Removed "${word}" from dictionary."`);
        this.getUserDictionaryWords(this.userId, true);
      });
    },
    handleClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.word-item {
  width: 15rem;
}
.word-search-panel {
  width: 15rem;
}
</style>
