<template>
  <div>
    <component
      :is="targetComponent"
      v-model="_value"
      v-on="$listeners"
      v-bind="targetComponentProps"
      :name="name"
      ref="editor"
      @toggleBaseSpellChecker="toggleEditorSpellChecker"
      :mentions="macroIntellisense"
      @initialized="onInitiailized"
      @customize-modules="customizeQuillModules"
      @blur="runSpellCheck"
    />
    <div
      class="spell-check-box"
      ref="spellCheckBox"
      v-if="selectedWordToCheck"
      v-bind="spellCheckBoxPosition"
    >
      <SpellCheckBox
        :wordToCheck="wordToCheck"
        @close="closeSpellCheck"
        @ignore="ignoreWord"
        @replace="handleReplaceWord"
        @addWord="handleAddWord"
        :isForcedUpperCase="isForcedUpperCase"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MacroModule, { macroStore } from "@/modules/Quill/MacroIntellisense.js";
import { MacroTypeEnum } from "@/modules/enums";
import Macro from "@/modules/Quill/Macro";
import Quill from "devextreme-quill";
import { capitalize, clearSpellCheckHtml, scrollToElement } from "@/modules/helpers";
import { getDefaultStyles } from "@/modules/getDefaultStylesByField";
import { SpellCheckApi } from "@/services";
import { uniqBy } from "lodash";
import SpellCheckBox from "../SpellCheckBox.vue";
import { filter, tap, debounceTime, map } from "rxjs/operators";

Quill.register({ "modules/macro": Macro });

export const regularExpressions = {
  group: /\.\.([rgpsmd|\s])$/i,
  single: /([.|\\])([\w\-+]+(;[dmsc])?)/i,
  decimalNumber: /[0-9]+[.]([0-9]+)/i,
  specimen: /((?:([.|\\])([\w\-+]+(;[dmsc])?))+),([\w*>=<]+)/i,
  specimenWithBlock: /(?:([.|\\])([\w\-+]+(;[dmsc])?))+,([\w*>=<]+)(,[\w])/i,
  block: /((?:[.][\w+-]+)+),(=?[\w*><=]{1,3}),([\w*><=]+)/i,
  singleWithoutDot: /^([\w\-+]+)(([.|\\])([\w\-+]+(;[dmsc])?))+/i,
  specimenWithoutDot: /((^([\w\-+]+(;[dmsc])?))+),([\w*>=<]+)/i
};

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    }
  },
  components: {
    Editor: () => import("@/components/common/Editor.vue"),
    DragonEditor: () => import("@/components/common/DragonEditor.vue"),
    SpellCheckBox
  },
  data: () => ({
    component: {},
    _editor: null,
    isSpellCheckRunning: false,
    intervalId: null,
    spellCheckIteration: 0,
    editorCheckerStatus: false,
    lastKeydown: 0,
    checkedWords: [],
    problemWords: [],
    ignoredWords: [],
    selectedWordToCheck: "",
    selectedElement: null,
    lastSpellCheckText: "",
    lastSpellCheckTime: 0,
    spellCheckBoxPosition: null,
    isOnSave: false,
    spellCheckElements: [],
    currentSpellCheckIndex: 0
  }),
  subscriptions() {
    const valueChanged$ = this.$watchAsObservable("value", { immediate: true }).pipe(
      filter(({ newValue }) => {
        return newValue;
      }),
      map(() => this.component.isMentionsOpen),
      filter(newValue => {
        return !newValue;
      }),
      debounceTime(500),
      tap(() => {
        this.runSpellCheck();
      })
    );

    return { valueChanged$ };
  },
  watch: {
    useDragonEditors: {
      handler() {
        const { isExpanded, isFocused } = this.$refs.editor;
        if (isExpanded) {
          setTimeout(() => {
            this.expand();
            if (isFocused) {
              this.$refs.editor.focus();
            }
          }, 500);
        }
      }
    },
    selectedWordToCheck: {
      handler(nv) {
        clearInterval(this.intervalId);
        if (nv) {
          this.intervalId = setInterval(() => {
            this.placeSpellCheckBox(this.selectedElement);
          }, 500);
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    onInitiailized({ component }) {
      if (component) {
        component.register({ "modules/mentions": MacroModule }, true);
      }
      this.component = component;
      this.$emit("editorReady", this.name);
    },
    focus() {
      if (this.$refs?.editor?.focus) {
        this.$refs.editor.focus();
      }
    },
    expand() {
      if (this.$refs?.editor?.expand) {
        this.$refs.editor.expand();
      }
    },
    collapse() {
      if (this.$refs?.editor) {
        this.$refs.editor.collapse();
      }
    },
    customizeQuillModules(config) {
      config.macro = {
        name: this.name,
        styles: this.styles,
        initialized: true
      };
    },
    toggleEditorSpellChecker(status) {
      this.editorCheckerStatus = status;
    },
    async runSpellCheck() {
      if (!this.enableSpellchecker || this.selectedWordToCheck || !this.value) {
        return;
      }
      const text = this.value.replaceAll(/<[^>]+>/gi, " ");
      if (!text?.length) {
        this.lastSpellCheckText = "";
        return;
      }
      this.lastSpellCheckTime = new Date().getTime();
      if (text === this.lastSpellCheckText) {
        return;
      }
      this.lastSpellCheckText = text;
      const wordList = uniqBy(
        text
          .split(/[^\w'-]+/g)
          .filter(e => e && /[a-z]/i.test(e) && !/^(\d+)?(x)?\d+([cm]m)?$/i.test(e))
          .map(e => e.toLowerCase())
      );
      this.checkedWords = wordList;
      const spellCheck = await SpellCheckApi.runSpellCheck(wordList);
      this.problemWords = spellCheck.filter(e => !e.correct);
      this.highlightAllProblemWords();
      return;
    },
    highlightAllProblemWords() {
      const editorRef = this.$refs.editor.$el;
      const editorEl = editorRef.getElementsByClassName("dx-htmleditor-content");
      const editorText = clearSpellCheckHtml(editorEl[0].innerHTML);
      let textToReplace = editorText;
      for (const { word } of this.problemWords) {
        if (!this.ignoredWords.includes(word.toLowerCase())) {
          textToReplace = this.highlightText(word, textToReplace);
        }
      }
      this.updateInnerHtml(textToReplace);
    },
    highlightText(word, text) {
      // Regex negative lookahead prevents words within HTML tags from being replaced
      const regex = new RegExp("(?![^<]*>)" + word, "ig");
      const matches = uniqBy([...text.matchAll(regex)].map(e => e[0]));
      for (const match of matches) {
        text = text.replaceAll(match, `<span class='spell-checked-words'>${match}</span>`);
      }
      return text;
    },
    async handleSelectWord(event) {
      event.preventDefault();
      const { target } = event;
      const editorWrapper = this.$refs.editor;
      const editorComponent = editorWrapper?.component;
      const { index } = editorComponent?.getSelection();
      const editorTextContent = editorComponent?.getText();
      let newSelection = { start: index, end: index };
      for (let i = index; i < editorTextContent.length; i++) {
        const letter = editorTextContent[i];
        if (/[^\w'-]/.test(letter)) {
          newSelection.end = i;
          break;
        }
        if (letter === undefined) {
          break;
        }
      }
      for (let i = index; i > -1; i--) {
        if (i === 0) {
          newSelection.start = 0;
          break;
        }
        const letter = editorTextContent[i];
        if (/[^\w'-]/.test(letter)) {
          newSelection.start = i + 1;
          break;
        }
        if (letter === undefined) {
          break;
        }
      }
      editorComponent.setSelection(newSelection.start, newSelection.end - newSelection.start);

      this.selectedElement = target;
      this.selectedWordToCheck = this.isForcedUpperCase
        ? target.textContent.toUpperCase()
        : target.textContent;
      this.isSpellCheckBoxOpen = true;
      this.$nextTick(() => {
        this.placeSpellCheckBox(target);
      });
    },
    closeSpellCheck() {
      this.selectedWordToCheck = "";
    },
    ignoreWord(word) {
      if (!this.ignoredWords.includes(word.toLowerCase())) {
        this.ignoredWords.push(word.toLowerCase());
        this.highlightAllProblemWords();
        this.selectNextWord();
      }
    },
    handleReplaceWord(word) {
      const delta = word.length - this.selectedElement.innerHTML.length;
      this.selectedElement.outerHTML = word;
      this.selectNextWord(delta);
    },
    handleAddWord(word) {
      this.problemWords = this.problemWords.filter(
        e => e.word.toLowerCase() !== word.toLowerCase()
      );
      this.highlightAllProblemWords();
      this.selectNextWord();
    },
    updateInnerHtml(text) {
      const editorWrapper = this.$refs.editor;
      const editorComponent = editorWrapper?.component;
      const selection = editorComponent?.getSelection();
      const editorRef = editorWrapper.$el;
      const editorEl = editorRef.getElementsByClassName("dx-htmleditor-content");
      editorEl[0].innerHTML = text;
      this.$nextTick(() => {
        const elements = editorRef.getElementsByClassName("spell-checked-words");
        for (const element of elements) {
          element.style.textDecoration = "underline wavy red";
          element.addEventListener("contextmenu", this.handleSelectWord);
        }
        if (editorWrapper?.isFocused && selection?.index) {
          editorComponent.setSelection(selection.index, 0);
        }
      });
    },
    async runSpellCheckOnSave() {
      if (!this.value) {
        this.$emit("spellCheckDone");
        return;
      }
      const editorRef = this.$refs.editor.$el;
      this.spellCheckElements = editorRef.getElementsByClassName("spell-checked-words");
      if (!this.spellCheckElements.length) {
        this.$emit("spellCheckDone");
        return;
      }
      const diff = scrollToElement(this.$refs.editor.$el);
      setTimeout(() => {
        this.isOnSave = true;
        this.spellCheckElements = editorRef.getElementsByClassName("spell-checked-words");
        this.currentSpellCheckIndex = 0;
        this.selectNextWord();
      }, diff * 0.75);
    },
    selectNextWord(delta = 0) {
      if (!this.isOnSave) {
        this.selectedWordToCheck = "";
        const editorWrapper = this.$refs.editor;
        const { index, length } = editorWrapper?.lastSelection;
        const editorComponent = editorWrapper?.component;
        this.$nextTick(() => {
          editorWrapper.focus(true, true);
          const newIndex = index + length + delta;
          editorComponent.setSelection(newIndex, 0);
        });
        return;
      }
      if (!this.spellCheckElements.length) {
        this.selectedWordToCheck = "";
        this.$emit("spellCheckDone");
        return;
      }
      this.handleSelectWord({
        target: this.spellCheckElements[0],
        preventDefault: () => {
          return;
        }
      });
      this.currentSpellCheckIndex++;
    },
    placeSpellCheckBox(element) {
      let { top, right, bottom } = element.getBoundingClientRect();
      const main = document.getElementsByTagName("main");
      const mainRect = main[0].getBoundingClientRect();
      const el = this.$refs.spellCheckBox;
      if (mainRect.top > top) {
        top = mainRect.top;
      } else if (mainRect.bottom < bottom) {
        const boxRect = el.getBoundingClientRect();
        const boxHeight = boxRect.bottom - boxRect.top;
        top = mainRect.bottom - boxHeight;
      }
      el.style.top = top + "px";
      el.style.left = right + "px";
    }
  },
  computed: {
    ...mapState({
      applicationSettings: state => state.applicationSettings,
      specimens: state => state.accessionStore.specimens,
      MacroSearchWithPeriod: state => state.labSettings.MacroSearchWithPeriod,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      SpellCheckOnSave: state => state.labSettings.SpellCheckOnSave,
      useDragonEditors: state => state.applicationSettings.useDragonEditors,
      labSettings: state => state.labSettings,
      enableSpellchecker: state => state.applicationSettings.enableSpellchecker
    }),
    ...mapGetters(["permissions"]),
    webSpellCheckInstance() {
      if (this.$refs.editor) {
        return this.$refs.editor.webSpellCheckInstance;
      }
      return null;
    },
    targetComponent() {
      return this.useDragonEditors ? "DragonEditor" : "Editor";
    },
    targetComponentProps() {
      return this.useDragonEditors
        ? { ...this.$attrs, isSpellCheckRunning: this.isSpellCheckRunning }
        : { ...this.$attrs, mentions: this.macroIntellisense, onInitiailized: this.onInitiailized };
    },
    targetList() {
      return [...(this.specimens.map(e => e.specimenOrder) || []), "*", ">", "<", ">=", "<="];
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    styles() {
      return getDefaultStyles(this.name, true);
    },
    macroIntellisense() {
      if (this.applicationSettings.macroAssist) {
        return [
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: ".",
            minSearchLength: 2,
            itemTemplate: ({ displayName, macroType }) => {
              if (macroType === MacroTypeEnum.Results) {
                return `<strong>${displayName}</strong>`;
              }
              return displayName;
            }
          },
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: "\\",
            minSearchLength: 2,
            itemTemplate: ({ displayName, macroType }) => {
              if (macroType === MacroTypeEnum.Results) {
                return `<strong>${displayName}</strong>`;
              }
              return displayName;
            }
          },
          {
            dataSource: this.targetList,
            marker: ",",
            minSearchLength: 0,
            isExtension: true
          },
          {
            dataSource: ["C", "D", "M", "S"],
            marker: ";",
            minSearchLength: 0,
            isExtension: true
          }
        ];
      }
      return [];
    },
    wordToCheck() {
      if (this.selectedWordToCheck) {
        const wordData = this.problemWords.find(
          e => e.word.toLowerCase() === this.selectedWordToCheck.toLowerCase()
        );
        return { ...wordData, word: this.selectedWordToCheck };
      }
      return null;
    },
    isForcedUpperCase() {
      let settingName = "";
      switch (this.name) {
        case "notes":
          settingName = "SpecimenNote";
          break;
        case "caseNotes":
          settingName = "CaseNote";
          break;
        default:
          settingName = capitalize(this.name);
      }
      if (settingName) {
        return Boolean(this.labSettings["ForceUpperCase" + settingName]);
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.toolbar {
  width: 100%;
}
.title {
  text-transform: capitalize;
  font-size: 1.2rem;
}
.is-invalid {
  border-color: #dc3545 !important;
}
.is-valid {
  border-color: #28a745 !important;
}

::v-deep .dx-icon,
::v-deep .dx-dropdowneditor-icon {
  &::before {
    color: #333 !important;
  }
}

.spell-check-box {
  position: absolute;
  top: 500px;
  left: 600px;
}
</style>
